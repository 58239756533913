import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AnatomyThreeScene from './AnatomyThree';

const AnatomyInviteCase = () => {
  const { roomNumber, userId } = useParams(); // Get params from the URL
  const [startSession, setStartSession] = useState(false); // Track whether the session has started

  // Validate the parameters
  if (!roomNumber || !userId) {
    return <p>Error: Invalid room number or user ID provided!</p>;
  }

  const handleStartSession = () => {
    setStartSession(true); // Trigger the start of the session
  };

  return (
    <div>
      {!startSession ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2>Welcome to the Anatomy Invite Session</h2>
          <p>
            Room Number: <strong>{roomNumber}</strong>
          </p>
          <p>
            User ID: <strong>{userId}</strong>
          </p>
          <button onClick={handleStartSession} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
            Start Session
          </button>
        </div>
      ) : (
        <AnatomyThreeScene roomNumber={roomNumber} userId={userId} />
      )}
      <br/>
      <br/>
    </div>
  );
};

export default AnatomyInviteCase;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";

// Styled components
const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 400px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    border-color: #555;
  }

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const StyledOption = styled.option`
  font-size: 1rem;
  color: #555;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #444;
  text-align: center;
  margin-bottom: 1rem;
`;

// Main Component
const CourseTopicSelector = ({ onTopicSelect }) => {
  const [listOfCourses, setListOfCourses] = useState([]);
  const [listOfTopics, setListOfTopics] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const access_token = useSelector((state) => state.userLogin.userInfo?.access);

  // Fetch courses on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const config = { headers: { Authorization: `Bearer ${access_token}` } };
        const response = await axios.get(
          "https://api.andrewslearning.com/api/getcourses/",
          config
        );
        setListOfCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    if (access_token) fetchCourses();
  }, [access_token]);

  // Fetch topics based on selected course
  const fetchTopics = async (courseId) => {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      const response = await axios.get(
        `https://api.andrewslearning.com/api/list_topics/${courseId}`,
        config
      );
      setListOfTopics(response.data);
    } catch (error) {
      alert("We’re currently working on the topics for this course. Thank you for your patience!")
      console.error("Error fetching topics:", error);
    }
  };

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    setSelectedCourse(courseId);
    fetchTopics(courseId);
  };

  const handleTopicChange = (event) => {
    const topicId = event.target.value;
    setSelectedTopic(topicId);
    if (onTopicSelect) onTopicSelect(topicId); // Pass selected topic back to parent
  };

  return (
    <SelectorContainer>
      <SectionTitle>Select a Course and Topic</SectionTitle>
      <div>
        <Label htmlFor="course-select">Select Course:</Label>
        <StyledSelect
          id="course-select"
          value={selectedCourse || ""}
          onChange={handleCourseChange}
        >
          <StyledOption value="" disabled>
            Choose a course
          </StyledOption>
          {listOfCourses.map((course) => (
            <StyledOption key={course.id} value={course.id}>
              {course.name}
            </StyledOption>
          ))}
        </StyledSelect>
      </div>

      {listOfTopics.length > 0 && (
        <div>
          <Label htmlFor="topic-select">Select Topic:</Label>
          <StyledSelect
            id="topic-select"
            value={selectedTopic || ""}
            onChange={handleTopicChange}
          >
            <StyledOption value="" disabled>
              Choose a topic
            </StyledOption>
            {listOfTopics.map((topic) => (
              <StyledOption key={topic.id} value={topic.id}>
                {topic.title}
              </StyledOption>
            ))}
          </StyledSelect>
        </div>
      )}
    </SelectorContainer>
  );
};

export default CourseTopicSelector;

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Animation for slide-in effect
const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  z-index: 999; /* Below the cookie banner */
`;

const CookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #007bff;
  color: white;
  padding: 1.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Above the overlay */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
  animation: ${slideUp} 0.5s ease-out;
  border-top: 5px solid #ff5722;
`;

const BannerText = styled.div`
  margin: 0;
  flex: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Arial', sans-serif;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  background-color: white;
  color: #007bff;
  border: 2px solid white;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0f2ff;
    color: #0056b3;
  }
`;

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [ipData, setIpData] = useState({ ip: null });
  const location = useLocation();
  let startTime = new Date();

  // Function to set the session cookie
  const setSessionCookie = () => {
    const sessionId = uuidv4();
    document.cookie = `session_id=${sessionId}; path=/; SameSite=Strict; Secure`;
    console.log('Session ID set:', sessionId);
    return sessionId;
  };

  // Function to get the session cookie
  const getSessionCookie = () => {
    const cookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('session_id='));
    return cookie ? cookie.split('=')[1] : null;
  };

  // Fetch the user's IP address
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      setIpData({ ip: response.data.ip });
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  // Initialize session and IP data
  useEffect(() => {
    fetchIpAddress();
    const consentGiven = localStorage.getItem('cookies_accepted');
    if (!consentGiven) {
      setShowBanner(true);
    } else if (consentGiven === 'true' && !getSessionCookie()) {
      setSessionCookie();
    }
  }, []);

  // Heartbeat tracking for active users
  useEffect(() => {
    const sessionId = getSessionCookie();
    if (sessionId && ipData.ip) {
      const sendHeartbeat = async () => {
        const pageData = {
          session_id: sessionId,
          page_url: window.location.href,
          page_path: location.pathname,
          ip_address: ipData.ip,
          timestamp: new Date().toISOString(),
          referrer_url: document.referrer || 'Direct', // Add referrer info or fallback to "Direct"
        };

        try {
          await axios.post(`https://api.andrewslearning.com/api/track-page/heartbeat`, pageData, {
            withCredentials: true,
          });
          console.log('Heartbeat sent:', pageData);
        } catch (error) {
          console.error('Error sending heartbeat:', error);
        }
      };

      const interval = setInterval(sendHeartbeat, 10000); // Every 10 seconds
      sendHeartbeat(); // Initial heartbeat

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [ipData.ip, location.pathname]);

  // Handle session tracking on page unload
  useEffect(() => {
    const consentGiven = localStorage.getItem('cookies_accepted');
    const sessionId = getSessionCookie();

    if (consentGiven === 'true' && sessionId) {
      const handleUnload = async () => {
        const endTime = new Date();
        const timeSpent = Math.round((endTime - startTime) / 1000);

        const pageData = {
          session_id: sessionId,
          page_url: window.location.href,
          page_path: location.pathname,
          timestamp: startTime.toISOString(),
          time_spent: timeSpent,
          ip_address: ipData.ip,
          referrer_url: document.referrer || 'Direct', // Add referrer info or fallback to "Direct"
        };

        if (navigator.sendBeacon) {
          const url = `https://api.andrewslearning.com/api/track-page/`;
          const blob = new Blob([JSON.stringify(pageData)], { type: 'application/json' });
          navigator.sendBeacon(url, blob);
        } else {
          try {
            await axios.post(`https://api.andrewslearning.com/api/track-page/`, pageData, {
              withCredentials: true,
            });
          } catch (error) {
            console.error('Error tracking page view:', error);
          }
        }
      };

      window.addEventListener('beforeunload', handleUnload);

      return () => {
        handleUnload();
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
  }, [location, ipData]);

  // Handle acceptance of cookies
  const handleAccept = () => {
    localStorage.setItem('cookies_accepted', 'true');
    setSessionCookie();
    setShowBanner(false);
  };

  // Handle decline of cookies
  const handleDecline = () => {
    localStorage.setItem('cookies_accepted', 'false');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <>
        <Overlay /> {/* Add the dark overlay */}
        <CookieBanner>
          <BannerText>
            <Icon src="https://cdn-icons-png.flaticon.com/512/2917/2917995.png" alt="Flask Icon" />
            We use cookies to improve your experience.
            
          </BannerText>
          <ButtonContainer>
            <Button onClick={handleAccept}>Accept</Button>
            <Button onClick={handleDecline}>Decline</Button>
          </ButtonContainer>
        </CookieBanner>
      </>
    )
  );
};

export default CookieConsent;

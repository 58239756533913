import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import './InviteLink.css';

const InviteLink = ({ roomNumber, topics }) => {
  if (!roomNumber || !topics || topics.length === 0) {
    return <p>Room number or topics not available!</p>;
  }

  const topicList = topics.join(', ');
  const inviteLink = `http://andrewslearning.com/joinquiz/${topics.join(',')}/${roomNumber}/`;

  const copied = () => {
    alert('Copied');
  };

  return (
    <div className="invite-link-container">
      <h3>Invite Others to Join!</h3>
      <p>
        Room Number: <strong>{roomNumber}</strong>
      </p>
      <p>
        Topics: <strong>{topicList}</strong>
      </p>
      <p>
        Invite Link:
        <a href={inviteLink} target="_blank" rel="noopener noreferrer">
          {inviteLink}
        </a>
      </p>
      <CopyToClipboard text={inviteLink}>
        <button onClick={copied} className="copy-button">
          Copy Invite Link
        </button>
      </CopyToClipboard>
      <div className="qrcode-container">
        <QRCodeSVG value={inviteLink} />
      </div>
    </div>
  );
};

export default InviteLink;

import React, { useRef, useState, useEffect } from 'react';

const Canvas = () => {
  const canvasRef = useRef(null);
  const [atoms, setAtoms] = useState([]); // Store atom positions
  const [bonds, setBonds] = useState([]); // Store bonds
  const [isDrawingBond, setIsDrawingBond] = useState(false); // Track if drawing a bond
  const [bondStart, setBondStart] = useState(null); // Starting atom for bond
  const threshold = 15; // Detection radius for atom or bond

  // Utility to calculate the distance between two points
  const calculateDistance = (x1, y1, x2, y2) => {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  // Detect if the click is near an atom
  const detectAtom = (x, y) => {
    return atoms.find(
      (atom) => calculateDistance(atom.x, atom.y, x, y) < threshold
    );
  };

  // Detect if the click is near a bond
  const detectBond = (x, y) => {
    for (let bond of bonds) {
      const { start, end } = bond;
      const distanceToLine =
        Math.abs(
          (end.y - start.y) * x -
          (end.x - start.x) * y +
          end.x * start.y -
          end.y * start.x
        ) / calculateDistance(start.x, start.y, end.x, end.y);
      if (distanceToLine < threshold) {
        return bond;
      }
    }
    return null;
  };

  // Handle canvas click to add atoms or start/end a bond
  const handleCanvasClick = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const clickedAtom = detectAtom(x, y);

    if (clickedAtom) {
      if (!isDrawingBond) {
        // Start drawing a bond
        setBondStart(clickedAtom);
        setIsDrawingBond(true);
      } else {
        // Finish the bond
        setBonds((prevBonds) => [
          ...prevBonds,
          { start: bondStart, end: clickedAtom },
        ]);
        setIsDrawingBond(false);
        setBondStart(null);
      }
    } else if (!isDrawingBond) {
      // Add a new atom if not drawing a bond
      setAtoms((prevAtoms) => [
        ...prevAtoms,
        { x, y, element: 'C' }, // Default element is Carbon
      ]);
    }
  };

  // Draw atoms and bonds on the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw bonds
    bonds.forEach(({ start, end }) => {
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      ctx.strokeStyle = '#000';
      ctx.lineWidth = 2;
      ctx.stroke();
    });

    // Draw atoms
    atoms.forEach(({ x, y, element }) => {
      ctx.beginPath();
      ctx.arc(x, y, 10, 0, Math.PI * 2);
      ctx.fillStyle = '#ddd';
      ctx.fill();

      // Draw element symbol
      ctx.fillStyle = '#000';
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(element, x, y);
    });

    // Draw the preview bond if drawing
    if (isDrawingBond && bondStart) {
      canvas.addEventListener("mousemove", (e) => {
        const { left, top } = canvas.getBoundingClientRect();
        const mouseX = e.clientX - left;
        const mouseY = e.clientY - top;

        ctx.beginPath();
        ctx.moveTo(bondStart.x, bondStart.y);
        ctx.lineTo(mouseX, mouseY);
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
      });
    }
  }, [atoms, bonds, isDrawingBond, bondStart]);

  return (
    <canvas
      ref={canvasRef}
      width={800}
      height={600}
      style={{ border: '1px solid #ddd', margin: '20px auto', display: 'block' }}
      onClick={handleCanvasClick}
    />
  );
};

export default Canvas;

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import './AnatomyInviteLink.css';

const AnatomyInviteLink = ({ roomNumber, userId }) => {
  if (!roomNumber || !userId) {
    return <p>Room number or user ID not available!</p>;
  }

  // Generate the invite link using room number and host's user ID
  const inviteLink = `https://andrewslearning.com/joinanatomyinvite/${roomNumber}/${userId}/`;

  const copied = () => {
    alert('Copied to clipboard!');
  };

  return (
    <div className="anatomy-invite-link-container">
      <h3>Invite Others to Join the Anatomy Session!</h3>
      <p>
        Room Number: <strong>{roomNumber}</strong>
      </p>
      <p>
        Host ID: <strong>{userId}</strong>
      </p>
      <p>
        Invite Link:{" "}
        <a href={inviteLink} target="_blank" rel="noopener noreferrer">
          {inviteLink}
        </a>
      </p>
      <CopyToClipboard text={inviteLink}>
        <button onClick={copied} className="copy-button">
          Copy Invite Link
        </button>
      </CopyToClipboard>
      <div className="qrcode-container">
        <QRCodeSVG value={inviteLink} size={150} />
      </div>
      <br/>
      <br/>
    </div>
  );
};

export default AnatomyInviteLink;

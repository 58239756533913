import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import api from './api';
import { useSelector } from 'react-redux';
import '../assessmentpage.css';

function AssessmentPage() {
    const { assessmentId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const assessmentResponseId = location.state?.assessmentResponseId || null;

    const [assessmentDetails, setAssessmentDetails] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const userInfo = useSelector(state => state.userLogin.userInfo);
    const access_token = userInfo ? userInfo.access : null;


    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get(`/api/assessments/${assessmentId}`);
            const data = response.data;
            setAssessmentDetails(data);
            const questionsResponse = await api.get(`/api/assessments/${assessmentId}/questions/`);
            const questionsData = questionsResponse.data;
            setQuestions(questionsData);
            setSelectedAnswers(Array(questionsData.length).fill(null));
            console.log("questions",questionsData[0].type,"selected ansers",selectedAnswers)
        };
        fetchData();
    }, [assessmentId]);

        // Handle Multiple Select for MCQA
   


    const handleAnswerChange = (questionIndex, answer) => {
        setSelectedAnswers(prevAnswers => {
            const newAnswers = [...prevAnswers];
            newAnswers[questionIndex] = answer; // Only store a single answer for each question
            return newAnswers;
        });
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    };

    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        else handleSubmit();
    };
    const handleMultipleSelect = (choice, checked) => {
        if (checked) {
            setSelectedAnswers((prev) => [...prev, choice]);
        } else {
            setSelectedAnswers((prev) =>
                prev.filter((selected) => selected.id !== choice.id)
            );
        }
    };
    const handleSubmit = async () => {
        const answers = {};
        questions.forEach((question, index) => {
            answers[question.id] = selectedAnswers[index];
        });
        console.log('assessmentResponseId:', assessmentResponseId);
        console.log('answers:', answers);

        try {
            const response = await api.post('/api/submit_assessment/', 
                { 
                    assessment_response_id: assessmentResponseId, 
                    answers: answers 
                }, 
                { headers: { Authorization: `Bearer ${access_token}` } }
            );

            if (response.status >= 200 && response.status < 300) {
                const { score, possible_score } = response.data;
                alert(`You received ${score} points out of ${possible_score} points`);
                setSubmitted(true);
                // Redirect to the assessment review page after successful submission
                navigate(`/assessment_review/${assessmentResponseId}`);
            }
        } catch (error) {
            console.error('Error submitting assessment:', error);
            // Handle submission error here (optional)
        }
    };

    useEffect(() => {
        if (submitted) navigate(location.state.from || `/assessment_review/${assessmentResponseId}`);
    }, [submitted]);

    if (!assessmentDetails) return <div>Loading...</div>;

    return (
        <div className="assessment-container">
            <h4 className="assessment-heading">{assessmentDetails?.name}</h4>
            
            {assessmentDetails?.one_question_at_a_time ? (
                <div className="assessment-question-container">
                    {questions[currentQuestionIndex]?.image && (
                        <img 
                            className="assessment-question-image" 
                            src={questions[currentQuestionIndex].image} 
                            alt="Question" 
                        />
                    )}

                    <div className="assessment-question">
                        Q{currentQuestionIndex + 1}: {questions[currentQuestionIndex]?.text}
                    </div>

                    {questions[currentQuestionIndex]?.choices.map((choice, index) => (
                        <div key={index} className="assessment-choice">
                            <input 
                                type="radio" 
                                id={`choice-${currentQuestionIndex}-${index}`}
                                name={`question-${currentQuestionIndex}`}
                                value={choice.text}
                                checked={selectedAnswers[currentQuestionIndex] === choice.text}
                                onChange={() => handleAnswerChange(currentQuestionIndex, choice.text)}
                            />
                            <label htmlFor={`choice-${currentQuestionIndex}-${index}`}>
                                {choice.image && (
                                    <img 
                                        className="assessment-choice-image" 
                                        src={choice.image} 
                                        alt="Choice" 
                                    />
                                )}
                                {choice.text}
                            </label>
                        </div>
                    ))}

                    <button 
                        className="assessment-previous-button" 
                        onClick={handlePrevious}
                        disabled={currentQuestionIndex === 0}
                    >
                        Previous
                    </button>
                    <button 
                        className="assessment-submit-button" 
                        onClick={handleNext}
                    >
                        {currentQuestionIndex < questions.length - 1 ? "Next" : "Submit"}
                    </button>
                </div>
            ) : (
                questions.map((question, index) => (
                    <div key={question.id} className="assessment-question-container">
                        {question.image && (
                            <img 
                                className="assessment-question-image" 
                                src={question.image} 
                                alt="Question" 
                            />
                        )}

                        <div className="assessment-question">
                            Q{index + 1}: {question.text}
                        </div>

                        {question.choices.map((choice, choiceIndex) => (
                            <div key={choiceIndex} className="assessment-choice">
                                <input 
                                    type="radio" 
                                    id={`question-${index}-choice-${choiceIndex}`}
                                    name={`question-${index}`}
                                    value={choice.text}
                                    checked={selectedAnswers[index] === choice.text}
                                    onChange={() => handleAnswerChange(index, choice.text)}
                                />
                                <label htmlFor={`question-${index}-choice-${choiceIndex}`}>
                                    {choice.image && (
                                        <img 
                                            className="assessment-choice-image" 
                                            src={choice.image} 
                                            alt="Choice" 
                                        />
                                    )}
                                    {choice.text}
                                </label>
                            </div>
                        ))}
                    </div>
                ))
            )}

            {!assessmentDetails?.one_question_at_a_time && (
                <button 
                    className="assessment-submit-button" 
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            )}
        </div>
    );
}

export default AssessmentPage;
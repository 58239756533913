import React, { useRef, useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import Output from "./Output";
import './CodeEditor.css'

const CodeEditor = () => {
  const editorRef = useRef();
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [languages, setLanguages] = useState([]);

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };

  const onSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setValue(""); // Clear editor when switching languages
  };

  useEffect(() => {
    async function fetchRuntimes() {
      try {
        const response = await fetch("https://emkc.org/api/v2/piston/runtimes");
        const data = await response.json();
        const formattedLanguages = data.map(({ language, version }) => [
          language,
          version,
        ]);
        setLanguages(formattedLanguages);
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    }
    fetchRuntimes();
  }, []);

  return (
    <div className="code-editor-layout">
      <div className="editor-output-container">
        <div className="editor-container">
          <LanguageSelector
            language={language}
            onSelect={onSelect}
            languages={languages}
          />
          <Editor
            height="75vh"
            theme="vs-dark"
            language={language}
            value={value}
            onMount={onMount}
            onChange={(newValue) => setValue(newValue)}
            options={{
              minimap: { enabled: false },
            }}
          />
        </div>
        <Output editorRef={editorRef} language={language} />
      </div>
    </div>
  );
};

export default CodeEditor;

import React from 'react';

const Toolbar = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#f4f4f4',
      borderBottom: '1px solid #ddd',
    }}>
      <button onClick={() => alert('Select Carbon')}>C</button>
      <button onClick={() => alert('Select Oxygen')}>O</button>
      <button onClick={() => alert('Select Hydrogen')}>H</button>
      <button onClick={() => alert('Single Bond')}>Single Bond</button>
      <button onClick={() => alert('Double Bond')}>Double Bond</button>
      <button onClick={() => alert('Triple Bond')}>Triple Bond</button>
    </div>
  );
};

export default Toolbar;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import './livequizpage.css';
import { getUserDetails } from '../../actions/userActions';
import { motion, AnimatePresence } from 'framer-motion';
import Chatapplocal from './chatapplocal';
import Chatappembedded from './chatappembeded';
import { useRef } from 'react';
import useSound from '../../Sound';


function LiveQuizPage({  topics = 14, roomNumber }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [submittedPlayers, setSubmittedPlayers] = useState([]);
    const [playerScores, setPlayerScores] = useState({});
    const [animatedScores, setAnimatedScores] = useState({});
    const [previousCorrectAnswer, setPreviousCorrectAnswer] = useState(null);
    const [showAnswers, setShowAnswers] = useState(false);
    const [rankedPlayers, setRankedPlayers] = useState([]);
    const [messages, setMessages] = useState([]); // Stores feedback messages
    const [isChatOpen, setIsChatOpen] = useState(false); // Toggle chat visibility
    const [isThemePlaying, setIsThemePlaying] = useState(true);


    const { play, stop, setLoop } = useSound("sounds/the-incident-soundroll-main-version-16775-01-32.mp3")

    const correct_sound = useSound("sounds/coin-recieved-230517.mp3")
    const chatRef = useRef(null); // Ref for Chatappembedded

    // Redux states
    const access_token = useSelector((state) => state.userLogin?.userInfo?.access);
    const userDetails = useSelector((state) => state.userDetails);
    const user = userDetails?.user; // Access user directly

    // Local states
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [totalAnswers, setTotalAnswers] = useState(0);
    const [score, setScore] = useState(0);
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [countdown, setCountdown] = useState(null); // Countdown timer
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [timerRunning, setTimerRunning] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false)
    
  

  
    useEffect(() => {
      // Stop theme music when quiz is completed
      if (quizCompleted) {
      }
    }, [quizCompleted]);
    const calculateRankings = (scores = {}) => {
        console.log("Calculating rankings from:", scores);
        if (Object.keys(scores).length === 0) {
            console.warn("No scores available for rankings.");
            return;
        }
    
        const sortedPlayers = Object.entries(scores)
            .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
            .map(([username, score], index) => ({
                username,
                score,
                rank: index + 1,
            }));
        setRankedPlayers(sortedPlayers);
    };
    // Submit Answer Function
    const submitAnswer = (question_id) => {
        if (!selectedChoice) {
          console.error("No answer selected.");
          return;
        }
      
        // Determine if the answer is correct
      
        // Add feedback to messages state
        const feedbackMessage = 
           ` ${currentQuestion.text}"${selectedChoice.text}" is the users answer explain why they are right or wrong.`
         ;
      
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            question: currentQuestion.text,
            answer: selectedChoice.text,
            feedback: feedbackMessage,
          },
        ]);
      
       
        if (chatRef.current) {
            chatRef.current.sendMessage(`${feedbackMessage} (Question: ${currentQuestion.text})`);
          }
        const payload = {
          type: "submit_answer",
          questionid: question_id,
          answer: selectedChoice?.text || selectedChoice,
          test: "rest",
          userid: user?.id || null,
        };
      
        console.log("Submitting Answer:", payload);
        socket.send(JSON.stringify(payload));
      };
      
      useEffect(() => {
        console.log("user etails",userDetails);
        
    }, [userDetails]);

    const toggleMusic = () => {
        if (isThemePlaying) {
            stop();
        } else {
            play();
            setLoop(true);
        }
        setIsThemePlaying(!isThemePlaying);
    };

    
    
    useEffect(() => {
        console.log("Quiz Completed State Changed:", quizCompleted);
        
    }, [quizCompleted]);

    useEffect(() => {
        console.log("ranked players why are you empty",rankedPlayers)
        
    }, [rankedPlayers]);

    useEffect(() => {
        console.log(" players scores why are you empty",playerScores)
        
    }, [playerScores]);

    useEffect(() => {
        const animationSpeed = 20; // Delay in milliseconds for smoother animation
    
        Object.entries(playerScores).forEach(([playerId, newScore]) => {
            const currentScore = animatedScores[playerId] || 0;
    
            if (newScore !== currentScore) {
                let startScore = currentScore;
                const adjustScore = () => {
                    if (startScore !== newScore) {
                        // Increment or decrement toward newScore
                        startScore += startScore < newScore ? 1 : -1;
                        
    
                        setAnimatedScores((prev) => ({
                            ...prev,
                            [playerId]: startScore,
                        }));
                        
    
                        // Continue the animation after a delay
                        setTimeout(adjustScore, animationSpeed);
                    }
                };
                adjustScore();
            }
        });
    }, [playerScores]);
    
    
    
    useEffect(() => {
        console.log("player scores:", score);
    }, [score]);

    useEffect(() => {
        if (previousCorrectAnswer) {
            console.log("Highlighting correct answer:", previousCorrectAnswer);
            setShowAnswers(true);
            // Automatically reset highlight after delay if not done earlier
            const timer = setTimeout(() => {
                setShowAnswers(false);
                setPreviousCorrectAnswer(null); // Reset after animation
            }, 2000);
    
            return () => clearTimeout(timer); // Cleanup on component unmount
        }
    }, [previousCorrectAnswer]);
    
    useEffect(() => {
        console.log("player scores:", playerScores);
    }, [playerScores]);
    const startCountdown = () => {
        return new Promise((resolve) => {
            let timer = 3;
            const finishMessage = "BEGIN!";
            setCountdown(timer);
    
            const interval = setInterval(() => {
                timer -= 1;
                setCountdown(timer > 0 ? timer : finishMessage);
    
                if (timer <= 0) {
                    clearInterval(interval);
                    console.log("Countdown finished!");
    
                    // Show "BEGIN!" for 1 second before clearing
                    setTimeout(() => {
                        setCountdown(null);  // Clear after delay
                        resolve();  // Notify that the countdown is complete after clearing
                    }, 1000);
                }
            }, 1000);
        });
    };
    
    
        // Track changes to submittedPlayers
    useEffect(() => {
        console.log("Updated Submitted Players:", submittedPlayers);
    }, [submittedPlayers]);

    useEffect(() => {
        if (submittedPlayers.length === 0) {
            console.log("Clearing submitted players...");
            document.querySelectorAll('.user-card.submitted').forEach(card => {
                card.classList.remove('submitted');
            });
            console.log("Cleared submitted players and removed CSS class.");
        }
    }, [submittedPlayers]);
    // Ensure `userDetails` is always fetched
    useEffect(() => {
        if (!user) {
            console.log('Fetching user details...');
            dispatch(getUserDetails());
        }
    }, [dispatch, user]);

    // Fetch assessments and set the firstAssessmentId
   {/* useEffect(() => {
        const fetchAssessments = async () => {
            try {
                const response = await axios.get('https://api.andrewslearning.com/api/assessments/');
                const data = response.data;

                if (data.length === 0) {
                    setError('No assessments found.');
                    return;
                }

                setFirstAssessmentId(14);
            } catch (err) {
                setError('Error fetching assessments.');
            } finally {
                setLoading(false);
            }
        };

        fetchAssessments();
    }, []);*/}
    // Handle Multiple Select for MCQA
const handleMultipleSelect = (choice, checked) => {
    if (checked) {
        setSelectedChoice((prev) => [...prev, choice]);
    } else {
        setSelectedChoice((prev) =>
            prev.filter((selected) => selected.id !== choice.id)
        );
    }
};



    // Fetch quiz details when firstAssessmentId is set
   {/* useEffect(() => {
        const fetchQuizDetails = async () => {
            if (!firstAssessmentId) return;

            try {
                setLoading(true);
                const response = await axios.get(
                    `https://api.andrewslearning.com/api/assessments/${firstAssessmentId}`,
                    {
                        headers: { Authorization: `Bearer ${access_token}` },
                    }
                );
                setQuizDetails(response.data);
            } catch (err) {
                setError('Error fetching quiz details.');
            } finally {
                setLoading(false);
            }
        };

        fetchQuizDetails();
    }, [firstAssessmentId, access_token]);*/}

    // WebSocket setup
    useEffect(() => {
        if (!roomNumber || !topics) return;

        const newSocket = new WebSocket(`wss://api.andrewslearning.com/wschat/livequiz/${topics}/${roomNumber}/`);
        setSocket(newSocket);

        newSocket.onopen = () => {
            console.log('WebSocket connection established');
            newSocket.send(
                JSON.stringify({
                    type: 'join_quiz',
                    profile_id: user.id || 'guest',
                })
            );
        };

        newSocket.onmessage = (event) => {
            const messageData = JSON.parse(event.data);

            switch (messageData.type) {
                case 'welcome_message':
                    console.log(messageData.message);
                    break;

                case "user_list_update":
                    const message = messageData.message
                    if (Array.isArray(messageData.message)) {
                        console.log("Received valid user list:", messageData.message);
                        setConnectedUsers(messageData.message);
                    } else {
                        console.error("Invalid user list received:", messageData.message);
                    }
                    break;
                case 'player_submission':
                        console.log(`${messageData.message}`);
                        
                        // Update the list of submitted players
                        setSubmittedPlayers((prev) => {
                            if (!prev.includes(messageData.profile)) {
                                return [...prev, messageData.profile];
                            }
                            return prev;
                        });
                        console.log(submittedPlayers);
                        if (playerScores) {
                            calculateRankings(playerScores);
                          }
                        calculateRankings(playerScores);
                        break;

               
                case 'user_entry':
                    console.log('User joined:', messageData);
                    setConnectedUsers((prev) => {
                        // Avoid duplicates
                        if (!prev.includes(messageData.message)) {
                            return [...prev, messageData.message];
                        }
                        return prev;
                    });
                    console.log(connectedUsers);
                    break;

                case 'user_exit':
                    setConnectedUsers((prev) =>
                        prev.filter((username) => username !== messageData.username)
                    );
                    break;
                case "next_question":
                    console.log("Next Question:", messageData.question);

                    // Set correct answer and trigger highlight
                    setPreviousCorrectAnswer(messageData.previous_correct_answer);
                    setShowAnswers(true); // Trigger highlight
                
                    // Delay showing the next question to allow animation
                    setTimeout(() => {
                        setShowAnswers(false); // Hide highlights after delay
                        setPreviousCorrectAnswer(null); // Reset correct answer
                        setCurrentQuestion(messageData.question); // Update question
                    }, 5000); // 2-second delay for animation
                
                    // Reset submitted players list
                    setSubmittedPlayers([]);
                    document.querySelectorAll('.user-card.submitted').forEach(card => {
                        card.classList.remove('submitted');
                        console.log(`Removed 'submitted' class from:`, card);
                    });
                    console.log(submittedPlayers)
                    break;
        
                case "quiz_completed":
                    console.log("Quiz Completed!");
                    calculateRankings(playerScores);  // Rank players when quiz ends
                    
                    setQuizCompleted(true)
                    console.log("Quiz Completed State:", quizCompleted);


                    

                    break;
                
               
                

                case 'questions_are_prepared':
                    console.log(messageData.questions);
                    // Display next question after timer
                    setCurrentQuestion({
                        id: messageData.questions[0].id,
                        text: messageData.questions[0].text,
                        choices: messageData.questions[0].choices,
                        type: messageData.questions[0].type,
                        image:messageData.questions[0].image
                    });
            
                    console.log("Question is now displayed after timer.");
                    
                    
                    break;
        
                    case "start_timer":
                        console.log("Timer officially started!");
                    
                        // Ensure countdown completes before sending WebSocket and showing question
                        startCountdown().then(() => {
                            setTimerRunning(false); 
                    
                            // Send 'start_quiz' only after countdown ends
                            newSocket.send(
                                JSON.stringify({
                                    type: "start_quiz",
                                })
                            );
                    
                            // Start theme music after the timer ends
                            play();
                        });
                        break;
        
              
                case 'quiz_update':
                    console.log("Quiz Update Received:", messageData);
                
                    // Update player scores safely
                    setPlayerScores((prev) => {
                        // Create a deep copy of the previous state
                        const updatedScores = { ...prev }; 
                
                        // Extract necessary data
                        const playerId = messageData.user;
                        const scoreEarned = messageData.score || 0;
                
                        // Ensure player's score exists in the dictionary
                        if (!(playerId in updatedScores)) {
                            updatedScores[playerId] = 0;
                        }
                
                        // Accumulate the score
                        updatedScores[playerId] += scoreEarned;
                
                        console.log("Updated Player Scores:", updatedScores);
                        return updatedScores; 
                    });
                
                    // Update other state variables if needed
                    setTotalAnswers((prev) => prev + (messageData.total_answers || 0));
                    setScore((prev) => prev + (messageData.score || 0));
                    calculateRankings();
                    
                    break;
                    
                       
                        

                default:
                    console.warn('Unhandled WebSocket message type:', messageData.type);
            }
        };

        newSocket.onclose = () => console.log('WebSocket connection closed');
        newSocket.onerror = (error) => console.error('WebSocket error:', error);

        return () => {
            newSocket.close();
        };
    }, [topics, user]);

    if (loading) return <div>Loading..</div>;
    if (error) return <div className="error">{error}</div>;
    if (quizCompleted) {
        return (
            <div className="quiz-over-container">
                <br/><br/>
                <center>
                    <AnimatePresence>
                        {quizCompleted && (
                            <motion.div
                                className="quiz-over"
                                initial={{ y: 100, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -100, opacity: 0 }}
                                transition={{ duration: 0.6 }}
                            >
                                <h2>🎉 Quiz Completed! 🎉</h2>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <p className="quiz-summary">Thank you for playing!</p>
    
                    {rankedPlayers.length === 1 ? (
                        <p className="quiz-result-message">Good job, {rankedPlayers[0].username}! 🏆</p>
                    ) : rankedPlayers.length > 1 ? (
                        <div className="ranking-list">
                            <h3>Player Rankings</h3>
                            <ul>
                                {rankedPlayers.map(({ username, score, rank }) => (
                                    <li key={username} className="ranking-item">
                                        <span className="rank-number">#{rank}</span>
                                        <span className="player-name">{username}</span>
                                        <span className="player-score">{score} Points</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <p className="quiz-result-message">No players participated.</p>
                    )}
    
                    <button 
                        className="restart-quiz-button" 
                        onClick={() => window.location.reload()}
                    >
                        Restart Game
                    </button>
                </center>
                <br/><br/><br/><br/>
            </div>
        );
    }
    return (
        <div className="outer-quiz-container">
        <div className="quiz-container">
        {countdown && (
        <motion.h1
          className="countdown"
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1.5, opacity: 1 }}
          exit={{ scale: 0.5, opacity: 0 }}
          transition={{ duration: 0.5, repeat: Infinity }}
        >
          {countdown}
        </motion.h1>
      )}
           
            {/* Quiz Title 
            <h4 className="quiz-heading">{quizDetails?.name}</h4>*/}
    
            {/* Question Display */}
            {!timerRunning && currentQuestion ? (
                <div className="quiz-question-container">
                    <div className="quiz-question">
                    {currentQuestion.image && (
        <img 
          src={currentQuestion.image} 
          alt={currentQuestion.text} 
          className="choice-image" 
        />
      )}
                        {currentQuestion.text}</div>
                    
                    {currentQuestion.type === "MCQ" && (
       <form className="choices-list" onSubmit={(e) => e.preventDefault()}>
       {currentQuestion?.choices.map((choice) => {
  // Determine if the choice is the correct answer
  const isCorrect = showAnswers && choice.text === previousCorrectAnswer;

  // Determine if the choice is the wrong answer
  const isWrong = showAnswers && selectedChoice?.id === choice.id && !isCorrect;


  return (
    <label
      key={choice.id}
      className={`choice-item ${isCorrect ? 'correct-answer' : ''} ${
        isWrong ? 'wrong-answer' : ''
      }`}
    >
           {/* Conditionally render the image only if choice.image is not null */}
      {choice.image && (
        <img 
          src={choice.image} 
          alt={choice.text} 
          className="choice-image" 
        />
      )}
      <input
        type="radio"
        name="quiz-choice"
        value={choice.text}
        checked={selectedChoice?.id === choice.id}
        onChange={() => setSelectedChoice(choice)
        }
        
        className="quiz-input"
        disabled={showAnswers} // Disable input after answers are revealed
      />
      <span className="choice-bubble">{choice.text}</span>
    </label>
    
  );
})}

{currentQuestion && (
    <button 
        className="submit-answer-button"
        onClick={() => submitAnswer(currentQuestion.id)}  // Pass correct question_id
        disabled={!selectedChoice}
    >
        Submit Answer
    </button>
)}

   </form>
   
    )}

    {/* Multiple Choice Multiple Answers */}
    {currentQuestion.type === "MCQA" && (
        <form className="choices-list" onSubmit={(e) => e.preventDefault()}>
            {currentQuestion.choices.map((choice) => (
                <label key={choice.id} className="choice-item">
                    <input
                        type="checkbox"
                        name={`quiz-choice-${choice.id}`}
                        value={choice.text}
                        checked={selectedChoice?.includes(choice)}
                        onChange={(e) => handleMultipleSelect(choice, e.target.checked)}
                        className="quiz-input"
                    />
                    <span className="choice-bubble">{choice.text}</span>
                </label>
            ))}
            <button 
    className="submit-answer-button"
    onClick={() => submitAnswer(selectedChoice?.text || selectedChoice)}
    disabled={!selectedChoice}
>
    Submit Answer
</button>
        </form>
        
        
    )}

                </div>
            ) : (
                <button 
                    className="start-game-button" 
                    onClick={async () => {
                        socket?.send(JSON.stringify({ type: "start_timer" }));  // Send WebSocket message
                        console.log("WebSocket message sent after countdown.");
                    }}
                >
                    Start Game
                </button>
            )}
    
            {/* Connected Users Display */}
            <div className="connected-users-container">
    <h5>Players in the Game</h5>
    <div className="user-cards">
        {connectedUsers.map((username) => (
            <AnimatePresence key={username}>
                <motion.div
                    className={`user-card ${submittedPlayers.includes(username) ? 'submitted' : ''}`}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                >
                    <p className="user-card-username">{username}</p>
                    <p className="user-score">
                    Score: {animatedScores[username] || playerScores[username] || 0}
                    </p>

                    {submittedPlayers.includes(username) && (
                        <motion.span
                            className="submission-indicator"
                            initial={{ scale: 0 }}
                            animate={{ scale: 1.2 }}
                            transition={{
                                type: 'spring',
                                stiffness: 300,
                                damping: 15,
                                delay: 0.2,
                            }}
                        >
                            ✅ Answer Submitted
                        </motion.span>
                    )}
                </motion.div>
            </AnimatePresence>
        ))}
        <a onClick={toggleMusic} style={{ cursor: 'pointer' }}>
                <img
                    src={isThemePlaying ? '/image/musicplay.png' : '/image/musicstop.png'}
                    alt={isThemePlaying ? 'Music On' : 'Music Off'}
                    style={{ width: '40px', height: '40px' }}
                />
            </a>
     
    </div>
  
      
</div>

        </div>  
          {/* Floating Chat Toggle Button */}
          <div className='AI-button'>
           
        <button
          className="toggle-chat-button"
          onClick={() => setIsChatOpen((prev) => !prev)}
        >
          💬 {isChatOpen ? 'Close Chat' : 'Get More Information on Questions!'}
        </button>

        {/* Chat Window */}
      
          {isChatOpen && (
            <Chatappembedded
            ref={chatRef}
            messages={messages} // Pass current messages state
            isMiniView={true}
          /> 
          )}
         
        </div>
        <br></br>
     </div>

  );
}

export default LiveQuizPage;

import React, { useState } from "react";
import { executeCode } from "./codeeditorapi";
import "./codeoutput.css"

const Output = ({ editorRef, language }) => {
  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const runCode = async () => {
    const sourceCode = editorRef.current.getValue();
    if (!sourceCode) return;

    try {
      setIsLoading(true);
      const { run: result } = await executeCode(language, sourceCode);
      setOutput(result.output.split("\n"));
      setIsError(Boolean(result.stderr));
    } catch (error) {
      console.error("An error occurred while running the code:", error);
      alert("An error occurred. Unable to run code.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="output-container">
      <h3 className="output-title">Output</h3>
      <button
        className={`run-button ${isLoading ? "loading" : ""}`}
        onClick={runCode}
        disabled={isLoading}
      >
        {isLoading ? "Running..." : "Run Code"}
      </button>

      <div
        className={`output-box ${isError ? "error" : ""}`}
      >
        {output
          ? output.map((line, i) => <p key={i}>{line}</p>)
          : 'Click "Run Code" to see the output here'}
      </div>
    </div>
  );
};

export default Output;

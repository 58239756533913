import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AnatomyThreeScene from './AnatomyThree';
import AnatomyInviteLink from './AnatomyInviteLink';

const AnatomyRegularCase = () => {
  const userInfo = useSelector((state) => state.userLogin.userInfo); // Fetch user info from Redux
  const [startExploration, setStartExploration] = useState(false); // Tracks whether to start anatomy exploration

  // Derive room number and user ID
  const roomNumber = userInfo ? userInfo.id * 35 : null;


  return (
    <div>
      {!startExploration ? (
        <div>
          <h2>Welcome to Anatomy Explorer!</h2>
          <p>Use the invite link below to invite others to join your session:</p>
          {/* Show invite link */}
          <AnatomyInviteLink roomNumber={roomNumber} userId={userInfo.id || 2} />
          <center>
            <button onClick={() => setStartExploration(true)}>Start Anatomy Exploration</button>
          </center>
        </div>
      ) : (
        // Show the AnatomyThreeScene once exploration starts
        <AnatomyThreeScene roomNumber={roomNumber} userId={userInfo.id} />
      )}
      <br/>
      <br/>
    </div>
  );
};

export default AnatomyRegularCase;

import { useState, useEffect, useCallback } from 'react';

const useSound = (url) => {
    const [audio] = useState(() => {
        const sound = new Audio(url);
        sound.loop = false; // Default is no looping
        return sound;
    });

    const play = useCallback(() => {
        audio.currentTime = 0; // Start from the beginning
        audio.play().catch((error) => console.error("Audio playback failed:", error));
    }, [audio]);

    const stop = useCallback(() => {
        audio.pause();
        audio.currentTime = 0; // Reset to the start
    }, [audio]);

    const setLoop = useCallback((shouldLoop) => {
        audio.loop = shouldLoop;
        console.log(`Loop set to: ${shouldLoop}`); // Debugging to confirm loop setting
    }, [audio]);

    useEffect(() => {
        // Cleanup function to stop audio on component unmount
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [audio]);

    return { play, stop, setLoop };
};

export default useSound;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Message = ({ text, sent, author, authorPic }) => {
  // State to hold profile picture
  const [profilePic, setProfilePic] = useState(null);

  // Get the user information from Redux state
  const userDetails = useSelector(state => state.userDetails);

  useEffect(() => {
    // Check if userDetails and authorPic are defined and set the profile picture accordingly
    if (userDetails && userDetails.user && userDetails.user.Propic) {
      setProfilePic(userDetails.user.Propic);
    } else {
      setProfilePic(null); // No profile picture available
    }
    
  }, [userDetails, authorPic]); // Run this effect when userDetails or authorPic changes
  console.log(profilePic);
  return (
    <div className={`Message ${sent ? 'sent' : 'received'}`}>
      {/* Display profile picture for both sent and received messages */}
      <div className="profile-pic-container">
        {profilePic ? (
          <img
            src={profilePic}
            alt={`${author}'s Profile`}
            className="profile-pic"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              objectFit: 'cover',
              marginRight: '10px',
            }} // Add inline styles to style and shrink the image
          />
        ) : (
          <span className="placeholder-pic">👤</span> // Fallback if no profile pic
        )}
      </div>

      {/* Display the message bubble */}
      <div className='message-bubble'>
        <strong className="author-name">
          {author}
        </strong>
        <span className="message-text">{text}</span>
        
      </div>
      <br/>
      <br/>
    </div>
    
  );
};

export default Message;

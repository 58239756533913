import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import LiveQuizPage from './LiveQuizPage';

const InviteQuizCase = () => {
  const { roomNumber, topics } = useParams();
  const [startQuiz, setStartQuiz] = useState(false); // Tracks whether to start the quiz

  // Parse topics if they are provided as a comma-separated string
  const parsedTopics = topics ? topics.split(',') : [];

  if (!roomNumber || parsedTopics.length === 0) {
    return <p>Error: Invalid room number or topics provided!</p>;
  }

  const handleStartQuiz = () => {
    setStartQuiz(true);
  };

  return (
    <div>
      <div className="instructional-container">
        <center>
          <h2>Join the Live Quiz!</h2>
          {!startQuiz && (
            <p>
              Topics: <strong>{parsedTopics.join(', ')}</strong>
            </p>
          )}
          {!startQuiz && (
            <p>
              Room Number: <strong>{roomNumber}</strong>
            </p>
          )}
        </center>
      </div>

      <div>
        {/* Conditionally render the LiveQuizPage */}
        {startQuiz ? (
          <LiveQuizPage topics={parsedTopics} roomNumber={roomNumber} />
        ) : (
          <center>
            <button onClick={handleStartQuiz} className="start-quiz-button">
              Start Quiz
            </button>
          </center>
        )}
      </div>
      <br/>
      <br/>
    </div>
  );
};

export default InviteQuizCase;

import React from "react";

const LanguageSelector = ({ language, onSelect, languages }) => {
  return (
    <div className="selector-container">
      <label className="label" htmlFor="language-select">
        Language:
      </label>
      <select
        id="language-select"
        className="select-box"
        value={language}
        onChange={(e) => onSelect(e.target.value)}
      >
        {languages.map(([lang, version]) => (
          <option key={lang} value={lang}>
            {lang} ({version})
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Flashcard from './Notecards';
import './FlashcardStudyApp.css';
import axios from 'axios';

const FlashcardStudyApp = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [deckSize, setDeckSize] = useState(5);
  const [listOfTopics, setListOfTopics] = useState([]);
  const [listOfNotecards, setListOfNotecards] = useState([]);
  const [listOfCourses, setListOfCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isFlipped, setIsFlipped] = useState(false); // Track flip state
  const { user } = useSelector((state) => state.userDetails);
  const access_token = useSelector((state) => state.userLogin.userInfo?.access);

  const handleDeckSizeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0 && value <= listOfNotecards.length) {
      setDeckSize(value);
      setCurrentIndex(0);
    }
  };

  const handleNext = () => {
    if (currentIndex < deckSize - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleCourseSelect = (courseId) => {
    setSelectedCourse(courseId);
    fetchTopics(courseId);
  };

  const handleTopicSelect = (topicId) => {
    setSelectedTopics((prev) => {
      return prev.includes(topicId)
        ? prev.filter((id) => id !== topicId)
        : [...prev, topicId];
    });
  };

  const fetchTopics = async (courseId) => {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      const response = await axios.get(
        `https://api.andrewslearning.com/api/list_topics/${courseId}`,
        config
      );
      setListOfTopics(response.data);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  const fetchNotecards = async () => {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      const ids = selectedTopics.join(',');
      const response = await axios.get(
        `https://api.andrewslearning.com/api/flashcards/${ids}`,
        config
      );
      setListOfNotecards(response.data);
    } catch (error) {
      console.error("Error fetching flashcards:", error);
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const config = { headers: { Authorization: `Bearer ${access_token}` } };
        const response = await axios.get(
          "https://api.andrewslearning.com/api/getcourses/",
          config
        );
        setListOfCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [user, access_token]);

  useEffect(() => {
    if (selectedTopics.length > 0) {
      fetchNotecards();
    }
  }, [selectedTopics]);

  // Reset the flip state when the current index changes
  useEffect(() => {
    setIsFlipped(false);
  }, [currentIndex]);

  return (
    <div className="study-app">
      <h1 className="study-title">Study Flashcards</h1>

      <div className="controls">
        <label>Select Course:</label>
        <select
          className="select-input"
          onChange={(e) => handleCourseSelect(e.target.value)}
          value={selectedCourse}
        >
          <option value="">-- Choose a Course --</option>
          {listOfCourses.map((course) => (
            <option key={course.id} value={course.id}>
              {course.name}
            </option>
          ))}
        </select>
      </div>

      {listOfTopics.length > 0 && (
        <div className="controls">
          <label>Select Topics:</label>
          <div className="topic-list">
            {listOfTopics.map((topic) => (
              <label className="topic-item" key={topic.id}>
                <input
                  type="checkbox"
                  value={topic.id}
                  onChange={() => handleTopicSelect(topic.id)}
                  checked={selectedTopics.includes(topic.id)}
                />
                {topic.title}
              </label>
            ))}
          </div>
        </div>
      )}

      <div className="controls">
        <label htmlFor="deck-size">Number of Flashcards to Study:</label>
        <input
          className="number-input"
          type="number"
          id="deck-size"
          min="1"
          max={listOfNotecards.length}
          value={deckSize}
          onChange={handleDeckSizeChange}
        />
      </div>

      {listOfNotecards.length > 0 ? (
        <div className="flashcard-container">
          <Flashcard
            question={listOfNotecards[currentIndex]?.question}
            answer={listOfNotecards[currentIndex]?.answer}
            isFlipped={isFlipped} // Pass flip state
            setIsFlipped={setIsFlipped} // Pass state updater
          />
          <div className="navigation-buttons">
            <button className="nav-button" onClick={handlePrev} disabled={currentIndex === 0}>
              Previous
            </button>
            <button className="nav-button" onClick={handleNext} disabled={currentIndex === deckSize - 1}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <h2 className="placeholder">Please select topics to begin studying.</h2>
      )}
    </div>
  );
};

export default FlashcardStudyApp;

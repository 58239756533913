import React, { useEffect, useRef } from 'react';

const MolecularEditor = () => {
  const jsmeRef = useRef(null);

  useEffect(() => {
    // Load JSME script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'jsme/jsme.nocache.js';
    document.body.appendChild(script);

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Add event listener once the JSME component is initialized
    const handleClick = (event) => {
      console.log('Canvas clicked:', event);
      
      // Example: Log the coordinates of the click
      const { clientX, clientY } = event;
      console.log(`Clicked at X: ${clientX}, Y: ${clientY}`);

      // You can add logic to get details about the clicked object
      // (e.g., bond, atom, etc.) if JSME provides such APIs.
    };

    // Attach the listener to the container
    if (jsmeRef.current) {
      jsmeRef.current.addEventListener('click', handleClick);
    }

    // Cleanup listener on unmount
    return () => {
      if (jsmeRef.current) {
        jsmeRef.current.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div>
      <br />
      <br />
      <center>
        <div
          ref={jsmeRef}
          code="JME.class"
          name="JME"
          archive="JME.jar"
          width="760"
          height="715"
          id="JME"
        >
          <param name="smiles" value="CN=C=O" />
          You have to enable JavaScript in your browser to use JSME!
        </div>
      </center>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default MolecularEditor;

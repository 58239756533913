import React from 'react';
import './InteractiveLabs.css'; // Importing the CSS file
import useSound from '../Sound';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function InteractiveLabs() {
    const  {playClickSound,stop,loop} = useSound('/ui-click-43196.mp3'); // Local file path
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate(); // Hook to programmatically navigate

    const handleSound = () => {
       
    };

    const handleAccess = (path) => {
       
        if (userInfo) {
            navigate(path); // Navigate if the user is logged in
        } else {
            alert("Please login to check out interactive applications, they're free!");
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    return (
        <div className="outer-lab-container">
            <div className="lab-container">
                <h1 className="lab-title">Interactive Labs</h1>
                <div className="lab-sections">
                    <div className="lab-section">
                        <h2>Biochemistry/Biology Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/MoleculeViewer')}>
                                <img 
                                    src="image/protein.png" 
                                    alt="Protein" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                                Macromolecule Visualizer</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/anatomy2')}> <img 
                                    src="image/SKELETONIMAGE.png" 
                                    alt="Anatomy app" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Anatomy Lab</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Organic/General Chemistry Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/chemstuff')}>
                                <img 
                                    src="image/chemdrawing.png" 
                                    alt="Chemistry app" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Molecular Editor</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/atom')}>
                                <img 
                                    src="image/magnesiumpic.png" 
                                    alt="Magnesium" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Periodic Table Bohr Model</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Computer Programming</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/code-editor')}>
                                <img 
                                    src="image/javascript.png" 
                                    alt="Nomenclature Diagram" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Code Editor</a>
                            </li>
                        
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Machine Learning</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/ML')}>
                                <img 
                                    src="image/Linearregression.png" 
                                    alt="Linear regression" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Linear Regression</a>
                            </li>
                        
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Physics Lab</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab1')}>
                                <img 
                                    src="image/BALL_BOUNCING.png" 
                                    alt="wavegraph" 
                                    style={{ width: '80%', height: '25%' }}
                                /> <br/>Free Falling Ball</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab2')}><img 
                                    src="image/collision.png" 
                                    alt="wavegraph" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Momentum</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab3')}> <img 
                                    src="image/wave1pic.png" 
                                    alt="wavegraph" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Wave Sketch</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Physicslab5')}> <img 
                                    src="image/wave2pic.png" 
                                    alt="Linear regression" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Wave Interference</a>
                            </li>
                        </ul>
                    </div>
                    <div className="lab-section">
                        <h2>Algebra Labs</h2>
                        <ul className="lab-list">
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Algebralab2')}>
                                <img 
                                    src="image/intersection.png" 
                                    alt="alg sets" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                               Set Operations</a>
                            </li>
                            <li className="lab-link">
                                <a onClick={() => handleAccess('/Algebralab3')}>
                                <img 
                                    src="image/visual_math_functions.png" 
                                    alt="Math" 
                                    style={{ width: '80%', height: '25%' }} 
                                />
                                    Function Graph</a>
                            </li>
                          {/*  <li className="lab-link">
                                <a onClick={() => handleAccess('/Algebralab4')}> <img 
                                    src="image/equation1.png" 
                                    alt="Equation" 
                                    style={{ width: '80%', height: '25%' }} 
                                />Solve Equation</a>
                            </li>*/}
                        </ul>
                    </div>
                
                </div>
            </div>
        </div>
    );
}

export default InteractiveLabs;

// Notecards.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Flashcard.css';

const Flashcard = ({ question, answer }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => setFlipped(!flipped);

  return (
    <div>
      <center>
        <motion.div 
          className="flashcard-container" 
          onClick={handleFlip}
          whileTap={{ scale: 0.95 }}
        >
          <motion.div 
            className={`flashcard ${flipped ? 'flipped' : ''}`}
            animate={{ rotateY: flipped ? 180 : 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="front">
              <h2>{question}</h2>
            </div>
            <div className="back">
              <h2>{answer}</h2>
            </div>
          </motion.div>
        </motion.div>
      </center>
      <br />
    </div>
  );
};

export default Flashcard;

import React from 'react';
import Canvas from '../Canvas.js';
import Toolbar from '../Toolbar.js';

const MoleculeEditorFromScratch = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Toolbar />
      <Canvas />
    </div>
  );
};

export default MoleculeEditorFromScratch;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CourseTopicSelector from '../CourseTopicSelector';
import LiveQuizPage from './LiveQuizPage';
import InviteLink from '../InviteGuests';
const QuizCase = () => {
  const [Value, setValue] = useState(null); // To hold the selected topic
  const [Topic, setTopic] = useState(null); // Selected topic state
  const userDetails = useSelector((state) => state.userDetails); // Fetch user details from Redux
  const user = userDetails?.user;

  // Derive the room number from user id
  const roomNumber = user ? user.id * 35 : null;

  const handleTopicSelect = (topicId) => {
    setValue(topicId);
  };

  const choosetopic = () => {
    if (Value) {
      setTopic(Value);
    } else {
      console.error('No topic selected!');
    }
  };

  if (!user) {
    return <p>Error: User must be logged in to host a quiz!</p>;
  }

  return (
    <div>
      <div className="instructional-container">
        <center>
          <h2>How to Play: Quiz Game</h2>
        </center>
      </div>
      <div>
        <center>
          <CourseTopicSelector onTopicSelect={handleTopicSelect} />
          <button onClick={choosetopic}>Make Test</button>
        </center>
        <br />
        {/* Display the InviteLink component */}
        {Topic && (
          <InviteLink
            roomNumber={roomNumber}
            topics={[Topic]} // Pass topics as an array
          />
        )}
        <br />
        {/* Conditionally render LiveQuizPage only when Topic is valid */}
        {Topic ? (
          <LiveQuizPage topics={Topic} roomNumber={roomNumber} />
        ) : (
          <p>Please select a topic to start the quiz.</p>
        )}
      </div>
    </div>
  );
};

export default QuizCase;
